<template>
  <v-card class="mt-10">
    <v-card-title>
      <h4>اخر الشهادات</h4>
      <v-spacer></v-spacer>
      <v-btn to="/certificates" small outlined color="primary">عرض الكل</v-btn>
    </v-card-title>
    <v-data-table
      :mobile-breakpoint="0"
      :footer-props="$store.state.footer_props"
      :headers="headers"
      :items="last_certificates"
    >
      <template v-slot:[`item.number`]="{ item }">
        {{ item.number }}#
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-btn
          class="text-capitalize"
          :to="{
            name: 'customer_profile',
            query: { user_info_identifier: item.user_id },
          }"
          text
          color="blue"
        >
          <v-icon class="me-1" small>person</v-icon>
          {{ item.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ Intl.NumberFormat("en-US").format(item.amount.toFixed(2)) }}
      </template>
      <template v-slot:[`item.percent`]="{ item }">
        {{ item.percent }}%
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "Table",
  props: ["last_certificates"],
  data() {
    return {
      headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "name",
          text: "اسم المستخدم",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "end_date",
          text: "تاريخ الانتهاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الاستثماء",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
      ],
    };
  },
};
</script>
