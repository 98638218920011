var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-10"},[_c('v-card-title',[_c('h4',[_vm._v("اخر الشهادات")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/certificates","small":"","outlined":"","color":"primary"}},[_vm._v("عرض الكل")])],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"footer-props":_vm.$store.state.footer_props,"headers":_vm.headers,"items":_vm.last_certificates},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.number)+"# ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, item.created_at.indexOf("T")))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"to":{
          name: 'customer_profile',
          query: { user_info_identifier: item.user_id },
        },"text":"","color":"blue"}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v("person")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat("en-US").format(item.amount.toFixed(2)))+" ")]}},{key:"item.percent",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.percent)+"% ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }