<template>
  <v-container>
    <v-row class="mt-0 mt-sm-10" v-if="!data_loaded">
      <v-col cols="12" sm="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" sm="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" sm="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="table"
      class="mt-5"
      v-if="!data_loaded"
    ></v-skeleton-loader>

    <v-row v-if="data_loaded" class="mt-10">
      <v-col cols="12" sm="3">
        <v-card color="info">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                عدد العملاء
              </h3>
              <p class="text-center number">{{ dashoard_data.customers }}</p>
            </div>
            <v-divider></v-divider>
            <v-btn to="/customers" class="mt-3" small>عرض العملاء</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3">
        <v-card color="orange">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                عدد الشهادات
              </h3>
              <p class="text-center number">{{ dashoard_data.certificates }}</p>
            </div>
            <v-divider></v-divider>
            <v-btn to="/certificates" class="mt-3" small> عرض الشهادات</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3">
        <v-card color="success">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                طلبات الالتحاق
              </h3>
              <p class="text-center number">{{ dashoard_data.requests }}</p>
            </div>
            <v-divider></v-divider>
            <v-btn to="/requests" class="mt-3" small>عرض الطلبات</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3">
        <v-card color="red lighteen-2">
          <v-card-text class="align-center">
            <div class="d-flex justify-space-between mb-2">
              <h3 class="text-center white--text font-weight-medium">
                طلبات التحويل
              </h3>
              <p class="text-center number">{{ dashoard_data.transfers }}</p>
            </div>
            <v-divider></v-divider>
            <v-btn to="/Transfares" class="mt-3" small>عرض الطلبات</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Table
      v-if="data_loaded"
      :last_certificates="dashoard_data.last_certificates"
    ></Table>
  </v-container>
</template>

<script>
import Table from "@/components/dashboard/table";
export default {
  name: "Dashboard",
  data() {
    return {
      data_loaded: false,
      dashoard_data: {
        customers: 0,
        certificates: 0,
        requests: 0,
        last_certificates: [],
      },
    };
  },
  components: {
    Table,
  },
  methods: {
    get_dashboard() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "dashboard",
            method: "get",
          },
        })
        .then((res) => {
          this.dashoard_data = res.data.data;
          this.data_loaded = true;
        })
        .catch((err) => {
          // console.log(err.response);
        });
    },
  },
  mounted() {
    this.get_dashboard();
  },
};
</script>

<style lag="scss" scoped>
.number {
  font-size: 20px;
  color: #fff;
}
</style>
